<template>
  <section-item bgColor="#faf7fa" height="680px">
    <div class="wrapper-box">
      <div class="title">
        <div class="cn-name">{{ option.cnName }}</div>
        <div class="en-name">{{ option.enName }}</div>
      </div>
      <div class="capability-box">
        <div class="capability-inner" v-for="cap in option.capabilities">
          <img class="cap-img" :src="cap.icon"/>
          <div class="cap-info">
            <div class="cap-label"> {{ cap.label }}</div>
            <div class="cap-description"> {{ cap.description}}</div>
          </div>
        </div>
      </div>
    </div>
  </section-item>
</template>

<script>
export default {
  name: "technological-capability",
  props: {
    option: {
      type:Object,
      default: {}
    }
  }
}
</script>

<style lang="scss" scoped>
.wrapper-box {
  //width: 1960px;
  //padding: 80px 356px;
  display: flex;
  flex-direction: column;

  .title {
    display: flex;
    flex-direction: column;
    align-content: center;

    .cn-name {
      font-size: 24px;
      display: flex;
      justify-content: center;
    }

    .en-name {
      margin-top: 19px;
      font-size: 14px;
      color: #666666;
      display: flex;
      justify-content: center;
    }
  }

  .capability-box {
    margin-top: 54px;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;

    .capability-inner {
      width: 597px;
      height: 126px;
      display: flex;
      flex-direction: row;
      align-items: center;


      .cap-img {
        width: 80px;
        height: 80px;
      }

      .cap-info {
        margin-left: 38px;
        display: flex;
        flex-direction: column;
        justify-content: center;

        .cap-label {
          font-size: 24px;
        }

        .cap-description {
          margin-top: 20px;
          font-size: 14px;
          color: #666666;
        }
      }
    }

    .capability-inner:nth-child(2n+1) {
      margin-right: 14px;
    }
  }
}
</style>