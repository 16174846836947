<template>
  <section-item height="580px">
    <div class="wrapper-box">
      <div class="title">
        <div class="cn-name">{{ option.cnName }}</div>
        <div class="en-name">{{ option.enName }}</div>
      </div>
      <div class="quality-outer-box">
        <div class="quality-inner-box" v-for="item in option.items">
          <img class="img" :src="item.icon">
          <span class="label"> {{ item.label }}</span>
          <span class="description"> {{ item.description }}</span>
        </div>
      </div>
    </div>
  </section-item>
</template>

<script>
export default {
  name: "quality",
  props: {
    option: {
      type:Object,
      default: {}
    }
  }
}
</script>

<style lang="scss" scoped>
.wrapper-box {
  //width: 1960px;
  //padding: 80px 356px;
  display: flex;
  flex-direction: column;

  .title {
    display: flex;
    flex-direction: column;
    align-content: center;

    .cn-name {
      font-size: 24px;
      display: flex;
      justify-content: center;
    }

    .en-name {
      margin-top: 19px;
      font-size: 14px;
      color: #666666;
      display: flex;
      justify-content: center;
    }
  }

  .quality-outer-box {
    display: flex;
    flex-direction: row;
    justify-content: space-between;

    .quality-inner-box {
      padding: 40px 24px;
      width: 291px;
      height: 300px;
      display: flex;
      flex-direction: column;
      align-items: center;

      .img {
        width: 108px;
        height: 108px;
      }

      .label {
        font-size: 24px;
        margin-top: 15px;
      }

      .description {
        display: flex;
        align-items: center;
        font-size: 14px;
        color: #666666;
        margin-top: 19px;
      }
    }
  }
}
</style>