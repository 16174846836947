const data = {
    cnName: '技术能力',
    enName: 'Technological  capability',
    capabilities :[
        {
            icon: require("@/assets/process/cap/process-cap-1.png"),
            label: '多平台定制',
            description: 'APP丨ios/Android丨微信丨PC丨小程序丨微商城'
        },
        {
            icon: require("@/assets/process/cap/process-cap-2.png"),
            label: '成熟系统框架',
            description: '基于Linux系统丨Apache、Nginx服务器丨PHP/JAVA/Nodejs开发语言丨MySQL'
        },
        {
            icon: require("@/assets/process/cap/process-cap-3.png"),
            label: '高效技术开发',
            description: '功能梳理丨原型策划丨UI设计丨程序开发丨系统测试丨质量监督丨迭代运维'
        },
        {
            icon: require("@/assets/process/cap/process-cap-4.png"),
            label: '全程服务支持',
            description: '售前咨询丨需求分析调研丨团队建设丨问题处理丨售后支持丨迭代建议'
        },
        {
            icon: require("@/assets/process/cap/process-cap-5.png"),
            label: '服务器方案',
            description: '分布式数据库服务器丨分布式Web服务器丨大数据负载丨全球节点接入'
        },
        {
            icon: require("@/assets/process/cap/process-cap-6.png"),
            label: '系统安全保障',
            description: '自主研发框架丨高并发高可用丨多层级丨平滑迁移丨跨平台支持'
        }
    ]
}

module.exports = data