<template>
  <div>
    <section-item height="600px"
                  :style="{backgroundImage: 'url(' + require('@/assets/banner/develop-banner4.png') + ')' }"></section-item>
    <!--    <div class="service-banner">-->
    <!--      <img :src="require('@/assets/banner/develop-banner.png')">-->
    <!--    </div>-->
    <!--  服务展示  -->
    <process-detail v-for="(info,index) in processInfos" :option="info" :bg-color="index % 2 == 1 ? '#faf7fa' : '#fff'"></process-detail>
    <!--  技术能力  -->
    <technological-capability :option="capabilityOption"></technological-capability>
    <!--  质量保证    -->
    <quality :option="qualityData"></quality>
    <!--  服务流程    -->
    <section-item height="600px"
                  :style="{backgroundImage: 'url(' + require('@/assets/banner/develop-banner2.png') + ')' }"></section-item>
    <section-item height="350px">
      <img :src="require('@/assets/banner/develop-banner3.png')">
    </section-item>
  </div>
</template>

<script>
import processInfos from './process-info'
import processDetail from './process-detail'
import technologicalCapability from './technological-capability'
import capabilityOption from './technological-capability-data'
import quality from "./quality";
import qualityData from './quality-data'
export default {
  components: {processDetail, technologicalCapability, quality},
  data(){
    return {
      processInfos: processInfos,
      capabilityOption: capabilityOption,
      qualityData: qualityData
    }
  }
}


</script>
<style lang="scss" scoped>
.service-banner {

}

.service-content {
}

.process-detail-box {
  margin: 90px 0 114px 0;
}

.process-detail-box:nth-child(2n) {
  background-color: #F8F8F8;
}

.flow-img-box {
  padding: 80px 357px;

  .img {
    width: 1206px;
    height: 190px;
  }
}
</style>