const data = {
    cnName: '质量保证',
    enName: 'Quality  assurance',
    items: [
        {
            icon: require("@/assets/process/quality/process-quality-1.png"),
            label: '兼容测试',
            description: '快速验证App兼容性，完成安装/启动/运行/卸载等操作，收集出错信息、截图、日志等'
        },
        {
            icon: require("@/assets/process/quality/process-quality-2.png"),
            label: '真机调试',
            description: '通过浏览器、开发工具远程连接，远程实时操作，快速定位问题'
        },
        {
            icon: require("@/assets/process/quality/process-quality-3.png"),
            label: 'Bug管理',
            description: 'App透过全面缺陷发现、跟踪及处理，让上线版本更稳健'
        },
        {
            icon: require("@/assets/process/quality/process-quality-4.png"),
            label: '测试报告',
            description: '测试报告包含您App产品的几个维度数据，充分反应应用的健康程度'
        }
    ]
}

module.exports = data