<template>
  <section-item :bgColor="bgColor" height="580px">
    <div class="detail-box">
      <div class="box-title">
        <div class="top-line"></div>
        <div class="cn-name">{{ option.cnName || '中文名称' }}</div>
        <div class="en-name">{{ option.enName || 'english name' }}</div>
      </div>
      <div class="box-content"
           :style="{'flex-direction' : option.picPos && option.picPos === 'right'? 'row':'row-reverse'}">
        <div class="content-wrapper content-part"
             :style="{'margin-left': option.picPos && option.picPos === 'right'? '0':'120px'}">
          <div class="description">{{ option.content || '介绍内容' }}</div>
          <div class="icon-box">
            <div class="icon-wrapper-box" v-for="icon in option.icons">
              <div class="icon-wrapper">
                <img class="icon-img" :src="icon.pic">
                <span class="icon-label">{{ icon.name }}</span>
              </div>
            </div>
          </div>
        </div>
        <div class="img content-part" :style="{'margin-left': option.picPos && option.picPos === 'right'? '120px':'0'}">
          <img class="img-detail" :src="option.pic">
        </div>
      </div>
    </div>
  </section-item>
</template>

<script>
export default {
  name: "product-detail",
  props: {
    option: {
      type: Object,
      default: {}
    },
    bgColor: {
      type: String,
      default: ''
    }
  }
}
</script>

<style lang="scss" scoped>
.detail-box {
  display: flex;
  flex-direction: column;
  //width: 1920px;
  //padding: 0px 358px 0 362px;


  .box-title {
    display: flex;
    flex-direction: column;

    .top-line {
      width: 41px;
      height: 2px;
      background-color: #3491FF;
    }

    .cn-name {
      margin-top: 17px;
      font-size: 24px;
    }

    .en-name {
      margin-top: 7px;
      font-size: 14px;
      color: #666666;
    }
  }

  .box-content {
    margin-top: 40px;
    display: flex;

    .content-wrapper {
      width: 660px;
      display: flex;
      flex-direction: column;

      .description {
        height: 180px;
        font-size: 18px;
      }

      .icon-box {
        display: flex;
        flex-direction: row;
        justify-content: space-around;

        .icon-wrapper-box {
          -webkit-box-flex: 1;
          -webkit-flex: 1;
          flex: 1;

          .icon-wrapper {
            width: 150px;
            height: 100px;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            border: 1px solid #8EB5E3;

            .icon-img {
              width: 38px;
              height: 38px;
            }

            .icon-label {
              margin-top: 3px;
              font-size: 18px;
              color: #8EB5E3;
            }
          }

          .icon-wrapper:not(:last-child) {
            margin-right: 20px;
          }
        }


      }
    }

    .img {
      .img-detail {
        width: 420px;
        height: 277px;
      }

      display: flex;
      flex-direction: row-reverse;
    }


  }

}
</style>