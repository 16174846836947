const data = [
    {
        cnName: '软件定制开发',
        enName: 'Software  development',
        content: '标准+量身定制=100%满足您的需求',
        pic: require("@/assets/process/pic/process-pic-1.png"),
        picPos: 'right',
        icons: [
            {pic: require("@/assets/process/icon/process-icon-1.png"), name: '医疗'},
            {pic: require("@/assets/process/icon/process-icon-2.png"), name: '餐饮'},
            {pic: require("@/assets/process/icon/process-icon-3.png"), name: '教育'},
            {pic: require("@/assets/process/icon/process-icon-4.png"), name: '房产'}
        ]
    },
    {
        cnName: '小程序开发',
        enName: 'Applet development',
        content: '基于微信、QQ、浙里办、浙政钉，为您提供小程序开发、运营、培训、推广一体化解决方案，\n' +
            '帮助实现线上、线下互通。',
        pic: require("@/assets/process/pic/process-pic-2.png"),
        picPos: 'left',
        icons: [
            {pic: require("@/assets/process/icon/process-icon-5.png"), name: '无需下载'},
            {pic: require("@/assets/process/icon/process-icon-6.png"), name: '组件丰富'},
            {pic: require("@/assets/process/icon/process-icon-7.png"), name: '用完即走'},
            {pic: require("@/assets/process/icon/process-icon-8.png"), name: '一键分享'}
        ]
    },
    {
        cnName: 'APP开发',
        enName: 'App development',
        content: '专属定制研发应用软件，安全稳定的技术运维服务，为您提供全方位的app开发解决方案。',
        pic: require("@/assets/process/pic/process-pic-3.png"),
        picPos: 'right',
        icons: [
            {pic: require("@/assets/process/icon/process-icon-9.png"), name: 'ios'},
            {pic: require("@/assets/process/icon/process-icon-10.png"), name: '安卓'},
            {pic: require("@/assets/process/icon/process-icon-11.png"), name: 'HTML5'},
            {pic: require("@/assets/process/icon/process-icon-12.png"), name: '运维管理'}
        ]
    },
    {
        cnName: '数据大屏开发',
        enName: 'Data large screen development',
        content: '定制开发酷炫的管理驾驶舱，支持发布到多场景的数据大屏上进行可视化集中管控。',
        pic: require("@/assets/process/pic/process-pic-4.png"),
        picPos: 'left',
        icons: [
            {pic: require("@/assets/process/icon/process-icon-13.png"), name: '数据可视化'},
            {pic: require("@/assets/process/icon/process-icon-14.png"), name: '应用场景丰富'},
        ]
    },
    {
        cnName: '电商平台开发',
        enName: 'E-commerce  development',
        content: '可针对企业不同的业务特性提供不同模式的电商平台开发服务。',
        pic: require("@/assets/process/pic/process-pic-5.png"),
        picPos: 'right',
        icons: [
            {pic: require("@/assets/process/icon/process-icon-15.png"), name: '安全'},
            {pic: require("@/assets/process/icon/process-icon-16.png"), name: '稳定'},
            {pic: require("@/assets/process/icon/process-icon-17.png"), name: '可拓性强'}
        ]
    }
]

module.exports = data;